/* eslint @typescript-eslint/no-use-before-define: 0 */
/* eslint no-shadow: 0 */
/* eslint complexity: 0 */
import React, { useContext, useEffect, useState } from 'react';
import {
    type CoastguardError,
    type CoastguardEventHandlers,
    type CoastguardSettings,
    type ExtractEventHandlerParams,
    type FetchFunction,
    type FetchOptions,
    type OnInitializedEventHandler,
    type OnMainSessionEndedEventHandler,
    type OnMainSessionEndingEventHandler,
    type OnMainSessionStartedEventHandler,
    type OnMainSessionStartFailedEventHandler,
    type OnMainSessionStartingEventHandler,
    type OnMainSessionUpdatedEventHandler,
    type OnMainSessionUpdateFailedEventHandler,
    type Query,
    type UserDetails,
    type SpringBootError as SpringBootErrorInterface,
    type CoastguardContext as CoastguardType,
    type CoastguardProviderProps,
} from './types/Coastguard';


const AuthenticationState = {
    UNAUTHENTICATED: { id: 'UNAUTHENTICATED', authenticated: false, loading: false },
    AUTHENTICATING: { id: 'AUTHENTICATING', authenticated: false, loading: true },
    AUTHENTICATED: { id: 'AUTHENTICATED', authenticated: true, loading: false },
    REFRESHING: { id: 'REFRESHING', authenticated: true, loading: false },
    REFRESH_FAILED: { id: 'REFRESH_FAILED', authenticated: true, loading: false },
    UNAUTHENTICATING: { id: 'UNAUTHENTICATING', authenticated: true, loading: true },
    UNAUTHENTICATING_FORCED: { id: 'UNAUTHENTICATING', authenticated: true, loading: true },
};


const DefaultCoastguard: CoastguardSettings = {
    backendHost: '',
    backendWebsocketHost: '',

    endpointWebSocketPath: '/api/auth/socket',
    endpointWhoamiPath: '/api/auth/whoami',
    endpointApplicationAuthorizePath: '/api/auth/application/authorize',
    endpointApplicationRefreshTokenPath: '/api/auth/application/refreshtoken',
    endpointApplicationAccessTokenPath: '/api/auth/application/accesstoken',
    endpointApplicationLogoutPath: '/api/auth/application/logout',

    storageMode: 'AUTO',
    storageModeKey: 'csp_mode',
    storageSessionKey: 'csp_session',

    accessTokenRefreshSeconds: 300,
    redirectPath: null,

    manualLogoutSessionStorageKey: 'csp_manual_logout',
};

export class Coastguard {

    _settings = DefaultCoastguard;

    _events: { [Event in keyof CoastguardEventHandlers]: CoastguardEventHandlers[Event][] } = {
        onInitialized: [],

        onMainSessionStarting: [],
        onMainSessionStarted: [],
        onMainSessionStartFailed: [],
        onMainSessionUpdated: [],
        onMainSessionUpdateFailed: [],
        onMainSessionEnding: [],
        onMainSessionEnded: [],

        onImpersonationSessionStarting: [],
        onImpersonationSessionStarted: [],
        onImpersonationSessionStartFailed: [],
        onImpersonationSessionUpdated: [],
        onImpersonationSessionUpdateFailed: [],
        onImpersonationSessionEnding: [],
        onImpersonationSessionEnded: [],
    }

    _initialized = false;

    _mainAuthenticationState = AuthenticationState.UNAUTHENTICATED;
    _mainRefreshTimer: number | null = null;
    _mainRefreshPromise: Promise<boolean> | null = null;
    _mainAccessToken: string | null = null;
    _mainUserDetails: UserDetails | null = null;

    _logoutMessage = 'APP_LOGOUT';

    _websocket: WebSocket | null = null;
    _websocketInterval: NodeJS.Timer | null = null;

    constructor(settings: Partial<CoastguardSettings> = DefaultCoastguard) {
        this.logout = this.logout.bind(this);
        this.authorizeApplication = this.authorizeApplication.bind(this);
        this.completeApplicationAuthorization = this.completeApplicationAuthorization.bind(this);
        this._generateAuthorizationStateToken = this._generateAuthorizationStateToken.bind(this);

        this.fetch = this.fetch.bind(this);
        this.fetchJSON = this.fetchJSON.bind(this);
        this.fetchBlob = this.fetchBlob.bind(this);

        this._fetchAccessTokenAndUserDetails = this._fetchAccessTokenAndUserDetails.bind(this);
        this._restoreAuthenticationSession = this._restoreAuthenticationSession.bind(this);
        this._startRefreshMainSessionTimer = this._startRefreshMainSessionTimer.bind(this);
        this._stopRefreshMainSessionTimer = this._stopRefreshMainSessionTimer.bind(this);
        this._onRefreshMainSessionTimer = this._onRefreshMainSessionTimer.bind(this);
        this._refreshMainAccessTokenAndUserDetails = this._refreshMainAccessTokenAndUserDetails.bind(this);
        this._openWebsocket = this._openWebsocket.bind(this);
        this._tryOpenWebsocket = this._tryOpenWebsocket.bind(this);
        this._startOpenWebsocketInterval = this._startOpenWebsocketInterval.bind(this);
        this._closeWebsocket = this._closeWebsocket.bind(this);

        this.subscribe = this.subscribe.bind(this);
        this.onInitialized = this.onInitialized.bind(this);
        this.onMainSessionStarting = this.onMainSessionStarting.bind(this);
        this.onMainSessionStarted = this.onMainSessionStarted.bind(this);
        this.onMainSessionStartFailed = this.onMainSessionStartFailed.bind(this);
        this.onMainSessionUpdated = this.onMainSessionUpdated.bind(this);
        this.onMainSessionUpdateFailed = this.onMainSessionUpdateFailed.bind(this);
        this.onMainSessionEnding = this.onMainSessionEnding.bind(this);
        this.onMainSessionEnded = this.onMainSessionEnded.bind(this);

        this._persistManualLogoutSession = this._persistManualLogoutSession.bind(this);

        this._raiseEvent = this._raiseEvent.bind(this);
        this._throwError = this._throwError.bind(this);

        this._settings = {
            ...DefaultCoastguard,
            ...settings,
        };
    }

    initialize() {
        if (!this._initialized) {
            this.subscribe('onMainSessionStarted', () => this._startOpenWebsocketInterval());
            this.subscribe('onMainSessionEnded', () => this._closeWebsocket());

            this._initialized = true;
            this._raiseEvent('onInitialized');

            return this._restoreAuthenticationSession();
        }

        return Promise.resolve(undefined);
    }

    get isInitialized() {
        return this._initialized;
    }

    _assertInitialized() {
        if (!this._initialized) {
            this._throwError(new IllegalStateError('CSP instance not initialized'));
        }
    }

    get isAuthenticating() {
        return this._mainAuthenticationState === AuthenticationState.AUTHENTICATING;
    }

    get isUnauthenticating() {
        return this._mainAuthenticationState === AuthenticationState.UNAUTHENTICATING;
    }

    get isAuthenticationLoading() {
        return this._mainAuthenticationState.loading;
    }

    get isAuthenticated() {
        return this._mainAuthenticationState.authenticated;
    }

    get getMainAccessToken(): string | null {
        if (this._mainAuthenticationState.authenticated && this._mainAccessToken != null) {
            return this._mainAccessToken;
        }

        return null;
    }

    get getUserDetails(): UserDetails | null {
        if (this._mainAuthenticationState.authenticated && this._mainUserDetails != null) {
            return this._mainUserDetails;
        }

        return null;
    }

    get hasMainSession() {
        return this._mainAuthenticationState.authenticated;
    }

    get mainSessionAuthenticationState() {
        return this._mainAuthenticationState;
    }

    subscribe<Event extends keyof CoastguardEventHandlers>(eventName: Event, eventHandler: CoastguardEventHandlers[Event]) {
        if (eventName == null || typeof eventName !== 'string' || !this._events.hasOwnProperty(eventName)) {
            this._throwError(new InvalidArgumentError('Invalid event name'));
        }
        if (eventHandler == null || typeof eventHandler !== 'function') {
            this._throwError(new InvalidArgumentError('Invalid event handler'));
        }

        const eventHandlers = this._events[eventName] as CoastguardEventHandlers[Event][];
        eventHandlers.push(eventHandler);

        return () => {
            const currentHandlers = this._events[eventName] as CoastguardEventHandlers[Event][];
            const index = currentHandlers.indexOf(eventHandler);

            if (index >= 0) {
                currentHandlers.splice(index, 1);
            }
        };
    }

    onInitialized(eventHandler: OnInitializedEventHandler) {
        return this.subscribe('onInitialized', eventHandler);
    }

    onMainSessionStarting(eventHandler: OnMainSessionStartingEventHandler) {
        return this.subscribe('onMainSessionStarting', eventHandler);
    }

    onMainSessionStarted(eventHandler: OnMainSessionStartedEventHandler) {
        return this.subscribe('onMainSessionStarted', eventHandler);
    }

    onMainSessionStartFailed(eventHandler: OnMainSessionStartFailedEventHandler) {
        return this.subscribe('onMainSessionStartFailed', eventHandler);
    }

    onMainSessionUpdated(eventHandler: OnMainSessionUpdatedEventHandler) {
        return this.subscribe('onMainSessionUpdated', eventHandler);
    }

    onMainSessionUpdateFailed(eventHandler: OnMainSessionUpdateFailedEventHandler) {
        return this.subscribe('onMainSessionUpdateFailed', eventHandler);
    }

    onMainSessionEnding(eventHandler: OnMainSessionEndingEventHandler) {
        return this.subscribe('onMainSessionEnding', eventHandler);
    }

    onMainSessionEnded(eventHandler: OnMainSessionEndedEventHandler) {
        return this.subscribe('onMainSessionEnded', eventHandler);
    }

    _raiseEvent<Event extends keyof CoastguardEventHandlers>(eventName: Event, ...params: ExtractEventHandlerParams<CoastguardEventHandlers[Event]>) {
        if (eventName == null || typeof eventName !== 'string' || !this._events.hasOwnProperty(eventName)) return;
        const eventHandlers = this._events[eventName] as CoastguardEventHandlers[Event][];

        // @ts-ignore: When the "no implicit any" compiler option is enabled the TS compiler would complain about some
        // types not being assignable to 'never' on the third argument of the event handler.
        // The 'never' type comes from the empty tuple that is used as a default value in the CofanoSpringSecurityEventHandler generic
        //
        // We can safely ignore the typescript error here since we have already added the parameter constrains to the 'params' parameter of this function
        // so typescript has already verified the types of the paramaters where _raiseEvent is called.
        eventHandlers.forEach(evh => evh(this, eventName, ...params));
    }

    _throwError(error: CoastguardError) {
        error.cspInstance = this;
        console.error(error);
        throw error;
    }

    async logout(force = false, error: Error | null = null) {
        this._assertInitialized();
        if (!this._mainAuthenticationState.authenticated) {
            this._throwError(new IllegalStateError('CSP instance not authenticated'));
        }

        const mainAuthenticated = this._mainAuthenticationState.authenticated;
        const mainAccessToken = this._mainAccessToken;

        if (mainAuthenticated) {
            this._mainAuthenticationState = force ? AuthenticationState.UNAUTHENTICATING_FORCED : AuthenticationState.UNAUTHENTICATING;

            this._raiseEvent('onMainSessionEnding', force, force ? error : null);
        }

        if (mainAuthenticated) {
            this._stopRefreshMainSessionTimer();

            this._mainAuthenticationState = AuthenticationState.UNAUTHENTICATED;
            this._mainAccessToken = null;
            this._mainUserDetails = null;
        }

        this._persistManualLogoutSession(!force);

        if (mainAuthenticated) {
            try {
                await callFetch(springBootFetchJSON, 'DELETE', this._settings.backendHost + this._settings.endpointApplicationLogoutPath, null, null, mainAccessToken);
            } catch (ex) {}

            this._raiseEvent('onMainSessionEnded', force);
        }
    }

    _persistManualLogoutSession(manualLogout: boolean) {
        window.sessionStorage.setItem(this._settings.manualLogoutSessionStorageKey, String(manualLogout));
    }

    get isManuallyLoggedOut() {
        return window.sessionStorage.getItem(this._settings.manualLogoutSessionStorageKey) === 'true';
    }

    async authorizeApplication(independentSession = false) {
        this._assertInitialized();

        this._persistManualLogoutSession(false);

        try {
            const redirectUri = `${window.location.protocol}//${window.location.host + this._settings.redirectPath}`;
            const authorizationStateToken = this._generateAuthorizationStateToken();

            return await callFetch(springBootFetchJSON, 'GET', `${this._settings.backendHost + this._settings.endpointApplicationAuthorizePath}?redirectUri=${encodeURIComponent(redirectUri)}&independentSession=${encodeURIComponent(independentSession)}`);
        } catch (ex) {
            this._mainAuthenticationState = AuthenticationState.UNAUTHENTICATED;


            throw ex;
        }
    }

    async completeApplicationAuthorization(authorizationCode: string) {
        this._assertInitialized();

        if (authorizationCode == null) {
            this._throwError(new InvalidArgumentError('Authorization code is not valid'));
        }

        if (this._mainAuthenticationState === AuthenticationState.AUTHENTICATING) {
            this._throwError(new IllegalStateError('CSP instance already authenticating'));
        }

        if (this._mainAuthenticationState.authenticated) {
            this._throwError(new IllegalStateError('CSP instance already authenticated'));
        }

        this._mainAuthenticationState = AuthenticationState.AUTHENTICATING;

        this._raiseEvent('onMainSessionStarting', null);

        try {
            await callFetch(springBootFetchJSON, 'POST', this._settings.backendHost + this._settings.endpointApplicationRefreshTokenPath, null, {
                authorizationCode,
            });

        } catch (ex) {
            this._mainAuthenticationState = AuthenticationState.UNAUTHENTICATED;

            throw ex;
        }

        try {
            const { accessToken, userDetails } = await this._fetchAccessTokenAndUserDetails();

            this._mainAuthenticationState = AuthenticationState.AUTHENTICATED;
            this._mainAccessToken = accessToken;
            this._mainUserDetails = userDetails;

            this._startRefreshMainSessionTimer();

            this._raiseEvent('onMainSessionStarted', userDetails.username, this.getUserDetails!);
        } catch (ex) {
            this._mainAuthenticationState = AuthenticationState.UNAUTHENTICATED;
            this._mainAccessToken = null;
            this._mainUserDetails = null;

            this._raiseEvent('onMainSessionStartFailed', null, ex as Error);

            throw ex;
        }
    }

    _fetchAccessTokenAndUserDetails() {
        return callFetch<{ accessToken: string, userDetails: UserDetails }>(springBootFetchJSON, 'POST', this._settings.backendHost + this._settings.endpointApplicationAccessTokenPath);
    }

    _startRefreshMainSessionTimer(timeout = -1) {
        this._mainRefreshTimer = window.setTimeout(this._onRefreshMainSessionTimer, timeout === -1 ? this._settings.accessTokenRefreshSeconds * 1000 : timeout);
    }

    _stopRefreshMainSessionTimer() {
        if (this._mainRefreshTimer != null) {
            window.clearTimeout(this._mainRefreshTimer);
            this._mainRefreshTimer = null;
        }
    }

    _onRefreshMainSessionTimer() {
        if (this._mainAuthenticationState.authenticated) {
            this._refreshMainAccessTokenAndUserDetails().then(result => {
                if (this._mainAuthenticationState.authenticated) {
                    this._startRefreshMainSessionTimer(result ? -1 : 5000);
                } else {
                    this._stopRefreshMainSessionTimer();
                }
            });
        }
    }

    _refreshMainAccessTokenAndUserDetails() {
        if (this._mainRefreshPromise == null) {
            this._mainRefreshPromise = (async() => {
                if (!this._mainAuthenticationState.authenticated) {
                    return false;
                }

                this._mainAuthenticationState = AuthenticationState.REFRESHING;
                try {
                    const { accessToken, userDetails } = await this._fetchAccessTokenAndUserDetails();

                    if (!this._mainAuthenticationState.authenticated) {
                        return false;
                    }

                    this._mainAuthenticationState = AuthenticationState.AUTHENTICATED;
                    this._mainAccessToken = accessToken;
                    this._mainUserDetails = userDetails;

                    this._raiseEvent('onMainSessionUpdated', userDetails);

                    return true;
                } catch (ex) {
                    if (ex != null && ex instanceof SpringBootError && ex.status === 401 && this._mainAuthenticationState.authenticated) {
                        await this.logout(true, ex);
                    } else if (this._mainAuthenticationState === AuthenticationState.REFRESHING) {
                        this._mainAuthenticationState = AuthenticationState.REFRESH_FAILED;

                        this._raiseEvent('onMainSessionUpdateFailed', ex as Error);
                    }
                    return false;
                }

            })().finally(() => {
                this._mainRefreshPromise = null;
            });
        }

        return this._mainRefreshPromise;
    }

    _generateAuthorizationStateToken() {
        return Math.random().toString(36).substring(2, 8);
    }

    async _restoreAuthenticationSession() {
        this._mainAuthenticationState = AuthenticationState.AUTHENTICATING;
        this._raiseEvent('onMainSessionStarting', null);

        try {
            const { accessToken, userDetails } = await this._fetchAccessTokenAndUserDetails();

            this._mainAuthenticationState = AuthenticationState.AUTHENTICATED;
            this._mainAccessToken = accessToken;
            this._mainUserDetails = userDetails;

            this._startRefreshMainSessionTimer();

            this._raiseEvent('onMainSessionStarted', null, userDetails);

        } catch (ex) {
            this._mainAuthenticationState = AuthenticationState.UNAUTHENTICATED;
            this._mainAccessToken = null;
            this._mainUserDetails = null;

            this._raiseEvent('onMainSessionStartFailed', null, ex as Error);

            this._throwError(ex as CoastguardError);
        }
    }

    _startOpenWebsocketInterval() {
        this._tryOpenWebsocket();

        this._websocketInterval = setInterval(() => {
            this._tryOpenWebsocket();
        }, 30000);
    }

    _tryOpenWebsocket() {
        const websocket = this._websocket;
        const readyState = websocket?.readyState;
        const isConnected = websocket != null && readyState != null && (readyState === websocket.OPEN || readyState === websocket.CONNECTING);

        if (!isConnected) {
            this._openWebsocket();
        }
    }

    _openWebsocket() {
        if (!this._mainAuthenticationState.authenticated) {
            return;
        }

        let websocketUrl: string | URL = '';
        if (this._settings.backendWebsocketHost) {
            websocketUrl = this._settings.backendWebsocketHost + this._settings.endpointWebSocketPath;
        } else {
            const protocol = window.location.protocol.substring(0, window.location.protocol.length - 1).trim();
            if (protocol === 'http') {
                websocketUrl = `ws://${window.location.host + this._settings.endpointWebSocketPath}`;
            } else if (protocol === 'https') {
                websocketUrl = `wss://${window.location.host + this._settings.endpointWebSocketPath}`;
            }
        }

        if (websocketUrl.trim().length === 0) {
            this._throwError(new IllegalStateError('Malformed websocket connection endpoint'));
        }

        const token = this._mainAccessToken ? this._mainAccessToken : []; // empty list because of the protocol
        this._websocket = new WebSocket(websocketUrl, token);

        this._websocket.onopen = () => {
            console.info('[CSP] Info', 'Websocket connection established');
        };

        this._websocket.onerror = () => {
            console.error('[CSP] Error', 'Error while establishing websocket connection');
        };

        this._websocket.onclose = () => {
            console.info('[CSP] Info', 'Websocket connection closed');
        };

        this._websocket.onmessage = async(ev) => {
            if (ev.data.toString() !== this._logoutMessage) {
                this._throwError(new IllegalStateError('Unknown message'));
            }

            if (this._mainAuthenticationState.authenticated) {
                await this.logout(false);
            }
        };
    }

    _closeWebsocket() {
        if (this._websocket != null) {
            this._websocket.close();
        }

        if (this._websocketInterval != null) {
            clearInterval(this._websocketInterval);
        }
    }

    async fetch(url: RequestInfo, options: FetchOptions) {
        const { anonymousFetch = false, forceMainSession = false, ...fetchOptions } = options;

        if (anonymousFetch || !this._initialized || !this._mainAuthenticationState.authenticated) {
            return await springBootFetch(url, fetchOptions);
        }

        try {
            return await springBootFetch(url, {
                ...fetchOptions,
                headers: {
                    ...(fetchOptions != null ? fetchOptions.headers : null),
                    'X-Authorization': `Bearer ${this._mainAccessToken}`,
                },
            });
        } catch (ex) {
            if (ex == null || !(ex instanceof SpringBootError) || ex.status !== 401) {
                throw ex;
            }

            if (ex.exception !== 'nl.cofano.security.coastguardclient.exceptions.auth.ExpiredAuthTokenException') {
                throw ex;
            }
        }

        await this._refreshMainAccessTokenAndUserDetails();

        if (!this._mainAuthenticationState.authenticated) {
            throw new IllegalStateError('CSP instance no longer authenticated');
        }

        try {
            return await springBootFetch(url, {
                ...fetchOptions,
                headers: {
                    ...(fetchOptions != null ? fetchOptions.headers : null),
                    'X-Authorization': `Bearer ${this._mainAccessToken}`,
                },
            });
        } catch (ex) {
            if (ex != null && ex instanceof SpringBootError && ex.status === 401) {
                if (this._mainAuthenticationState.authenticated) {
                    await this.logout(true);
                }
            }

            throw ex;
        }
    }

    async fetchJSON(url: RequestInfo, options: FetchOptions) {
        let newOptions = { ...options };
        if (newOptions.body) {
            newOptions = {
                ...newOptions,
                body: JSON.stringify(newOptions.body),
                headers: {
                    ...newOptions.headers,
                    'Content-Type': 'application/json;charset=utf-8',
                },
            };
        }

        const response = await this.fetch(url, newOptions);
        const content = await response.text();
        try {
            return content.length > 0 ? JSON.parse(content) : null;
        } catch (ex) {
            throw new InvalidSpringBootResponse(content, ex as Error, response);
        }
    }

    async fetchBlob(url: RequestInfo, options?: FetchOptions) {
        let newOptions = { ...(options || {}) };
        if (newOptions.body) {
            newOptions = {
                ...newOptions,
                body: JSON.stringify(newOptions.body),
                headers: {
                    ...newOptions.headers,
                    'Content-Type': 'application/json;charset=utf-8',
                },
            };
        }

        newOptions = {
            ...newOptions,
            headers: {
                ...newOptions.headers,
                'Accept': 'application/octet-stream',
            },
        };

        const response = await this.fetch(url, newOptions);
        return await response.blob();
    }
}

export const CoastguardContext = React.createContext<CoastguardType | null>(null);

export function CoastguardProvider({ instance, children }: CoastguardProviderProps) {
    const [ { hasError, lastError }, setState ] = useState<{ hasError: boolean, lastError: Error | null}>({ hasError: false, lastError: null });

    useEffect(() => {
        instance.onInitialized(() => setState(({ lastError }) => ({ hasError: false, lastError })));
        instance.onMainSessionStarting(() => setState(({ lastError }) => ({ hasError: false, lastError })));
        instance.onMainSessionStarted(() => setState(({ lastError }) => ({ hasError: false, lastError })));
        instance.onMainSessionStartFailed((_sender, _event, _username, ex) => setState({ hasError: true, lastError: ex }));
        instance.onMainSessionUpdated(() => setState(({ lastError }) => ({ hasError: false, lastError })));
        instance.onMainSessionUpdateFailed((_sender, _event, ex) => setState({ hasError: true, lastError: ex }));
        instance.onMainSessionEnding((_sender, _event, force, ex) => setState(({ lastError }) => ({ hasError: force, lastError: force ? ex : lastError })));
        instance.onMainSessionEnded(() => setState(({ lastError }) => ({ hasError: false, lastError })));

        instance.initialize().catch(() => {});
    }, [ instance ]);

    return (<CoastguardContext.Provider
        value={{ instance, hasError, lastError }}>
        {children}
    </CoastguardContext.Provider>);
}

export const withCoastguard = (WrappedComponent: React.ElementType) => (props: any) => (
    <CoastguardContext.Consumer>
        {context => <WrappedComponent {...props } cgContext={context} />}
    </CoastguardContext.Consumer>);

export const useCoastguard = () => {
    return useContext(CoastguardContext)!;
};

export const useUserDetails = (): UserDetails | null => {
    const cg = useCoastguard();

    if (cg == null) {
        return null;
    }

    const { instance: { getUserDetails: userDetails } } = cg;

    return userDetails;
};

export class InvalidArgumentError extends Error implements CoastguardError {
    cspInstance?: Coastguard

    constructor(message?: string) {
        super(message);

        Object.defineProperty(this, 'name', {
            configurable: true,
            enumerable: false,
            writable: true,
            value: '[CSP] InvalidArgumentError',
        });

        captureStackTrace(this, InvalidArgumentError);
    }
}

export class IllegalStateError extends Error implements CoastguardError {
    cspInstance?: Coastguard

    constructor(message?: string) {
        super(message);

        Object.defineProperty(this, 'name', {
            configurable: true,
            enumerable: false,
            writable: true,
            value: '[CSP] IllegalStateError',
        });

        captureStackTrace(this, IllegalStateError);
    }
}

export class SpringBootError extends Error implements SpringBootErrorInterface, CoastguardError {
    response: Response
    status: number
    exception: string
    error: string
    path: string
    timestamp: number
    cspInstance?: Coastguard

    constructor(exception: SpringBootErrorInterface, response: Response) {
        super(exception.message);

        Object.defineProperty(this, 'name', {
            configurable: true,
            enumerable: false,
            writable: true,
            value: 'SpringBootError (' + exception.exception + ')',
        });

        this.response = response;
        this.status = exception.status;
        this.exception = exception.exception;
        this.error = exception.error;
        this.path = exception.path;
        this.timestamp = exception.timestamp;

        const handledProperties = [ 'message', 'response', 'status', 'exception', 'error', 'path', 'timestamp' ];
        Object.entries(exception).forEach(([ key, value ]: [ string, any ]) => {
            if (!handledProperties.includes(key)) {
                this[key] = value;
            }
        });

        captureStackTrace(this, SpringBootError);
    }
}

export class InvalidSpringBootError extends Error implements CoastguardError {
    status: Response['status']
    cause: Error
    response: Response
    cspInstance?: Coastguard

    constructor(status: Response['status'], text: string | undefined, cause: Error, response: Response) {
        super(text);

        Object.defineProperty(this, 'name', {
            configurable: true,
            enumerable: false,
            writable: true,
            value: 'InvalidSpringBootError',
        });

        this.status = status;
        this.cause = cause;
        this.response = response;

        captureStackTrace(this, InvalidSpringBootError);
    }
}

export class InvalidSpringBootResponse extends Error implements CoastguardError {
    cause: Error
    response: Response
    cspInstance?: Coastguard

    constructor(text: string, cause: Error, response: Response) {
        super(text);

        Object.defineProperty(this, 'name', {
            configurable: true,
            enumerable: false,
            writable: true,
            value: 'InvalidSpringBootResponse',
        });

        this.cause = cause;
        this.response = response;

        captureStackTrace(this, InvalidSpringBootResponse);
    }
}

export function captureStackTrace(error: Error, errorClass: Function) {
    if (Error.captureStackTrace) {
        Error.captureStackTrace(error, errorClass);
    } else {
        let stackTrace = (new Error()).stack;

        if (stackTrace != null && errorClass != null && errorClass.name != null) {
            const constructorName = errorClass.name + '@';
            const stackTraceLines = stackTrace.split('\n');

            let deleteCount = -1;
            let iterator = 0;
            while (iterator < stackTraceLines.length && deleteCount === -1) {
                const line = stackTraceLines[iterator];

                if (line != null && line.startsWith(constructorName)) {
                    deleteCount = iterator + 1;
                }

                iterator++;
            }

            stackTraceLines.splice(0, deleteCount);

            stackTrace = stackTraceLines.join('\n');
        }

        Object.defineProperty(error, 'stack', {
            configurable: true,
            enumerable: false,
            writable: false,
            value: stackTrace,
        });
    }
}

export async function callFetch<R>(fetchFunction: FetchFunction<R>, method: string, url: string, query: Query = null, body: any = null, token: string | null = null, headers: HeadersInit = {}, options: Omit<FetchOptions, 'method' | 'headers' | 'body'> = {}) {
    let fetchUrl = url;

    if (query) {
        const queryString = Object.entries(query).map(([ name, value ]) => {
            if (Array.isArray(value)) {
                const arrayFiltered = value.filter(arrayVal => !!arrayVal);
                if (arrayFiltered.length > 0) {
                    return value.filter(arrayVal => !!arrayVal).map(arrayVal => `${encodeURIComponent(name)}=${encodeURIComponent(arrayVal)}`).join('&');
                } else {
                    return null;
                }
            } else if (value != null) {
                return `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;
            } else {
                return null;
            }
        }).filter(e => !!e).join('&');
        if (queryString !== '') {
            fetchUrl += `?${queryString}`;
        }
    }

    let newHeaders = headers;
    if (token) {
        newHeaders = {
            ...newHeaders,
            'X-Authorization': `Bearer ${token}`,
        };
    }

    return await fetchFunction(fetchUrl, {
        ...options,
        method: method,
        headers: newHeaders,
        body: body,
    });
}

export async function springBootFetchJSON(url: RequestInfo, options?: FetchOptions) {
    let newOptions = { ...options };
    if (newOptions.body) {
        newOptions = {
            ...newOptions,
            body: JSON.stringify(newOptions.body),
            headers: {
                ...newOptions.headers,
                'Content-Type': 'application/json;charset=utf-8',
            },
        };
    }

    const response = await springBootFetch(url, newOptions);
    const content = await response.text();
    try {
        return content.length > 0 ? JSON.parse(content) : null;
    } catch (ex) {
        throw new InvalidSpringBootResponse(content, ex as Error, response);
    }
}

export async function springBootFetchBlob(url: RequestInfo, options: FetchOptions) {
    let newOptions = { ...options };
    if (newOptions.body) {
        newOptions = {
            ...newOptions,
            body: JSON.stringify(newOptions.body),
            headers: {
                ...newOptions.headers,
                'Content-Type': 'application/json;charset=utf-8',
            },
        };
    }

    newOptions = {
        ...newOptions,
        headers: {
            ...newOptions.headers,
            'Accept': 'application/octet-stream',
        },
    };

    const response = await springBootFetch(url, newOptions);
    return await response.blob();
}


export async function springBootFetch(url: RequestInfo, options: FetchOptions) {
    let acceptArray: string[] = [];
    if (!Array.isArray(options.headers)) {
        const headers = options.headers as Record<string, string>;
        acceptArray = (headers && headers['Accept']) ? headers['Accept'].split(',').map(x => x.trim()) : [];
    }
    const newOptions = {
        ...options,
        headers: {
            ...options.headers,
            'Accept': [ ...acceptArray, 'application/json;q=0.9' ].join(','),
        },
    };

    const response = await window.fetch(url, newOptions);
    if (!response.ok) {
        let content, error;
        try {
            content = await response.text();
        } catch (ex) {
            throw new InvalidSpringBootError(response.status, undefined, ex as Error, response);
        }

        try {
            error = new SpringBootError(JSON.parse(content), response);
        } catch (ex) {
            throw new InvalidSpringBootError(response.status, content, ex as Error, response);
        }

        throw error;
    }

    return response;
}
