import React, { useCallback, useMemo, useState } from 'react';
import { Alert, FormControlLabel, Grid, Radio, RadioGroup, } from '@mui/material';
import { Delete, Loop } from "@mui/icons-material";

import { Button, Card, FormGroup, Input, Select, snackbarService } from '@cofano/cck';

import {
    DeploymentStrategy,
    PropertyType,
    useGetEnvironmentUpdateDataQuery,
    useUpdateEnvironmentsMutation
} from '../../../__generated__/graphql';

import type { Property } from "../../../__generated__/graphql";

function doesVersionMatch(version, stream, allReleaseStreams) {
    if (!version || !stream) return true;
    if (stream === 'latest') return true; // We cannot check 'latest'

    // If we configured a regex on the release stream, we will validate the new version with that regex
    const configuredStream = allReleaseStreams.find(rs => rs.stream === stream);
    if (configuredStream != null && configuredStream.regex != null) {
        const regex = new RegExp(configuredStream.regex);
        return regex.test(version);
    }

    // No regex, so we just check if the release stream is part of the version
    return version.indexOf(stream.replace('v', '')) !== -1;
}

export function EnvironmentsUpdate({ application, environments, cancel }) {
    const [ newVersion, setNewVersion ] = useState<string>('');
    const [ releaseStream, setReleaseStream ] = useState<string | null>();
    const [ properties, setProperties ] = useState<Array<Property & { value: string | null }>>([]);
    const [ deploymentStrategy, setDeploymentStrategy ] = useState(DeploymentStrategy.Rolling);

    const [ updateEnvironmentsVersionMutation, { loading: mutationLoading } ] = useUpdateEnvironmentsMutation();
    const { data, loading } = useGetEnvironmentUpdateDataQuery({
        variables: {
            application: application,
            environmentName: environments[0].name,
        }
    });

    const releaseStreamOptions = useMemo(() => {
        return data?.releaseStreams.map(stream => stream.stream) || [];
    }, [ data ]);

    const updateProperty = useCallback((index, value) => {
        setProperties(currentProperties => {
            const newProperties = [ ...currentProperties ];
            newProperties[index] = {
                ...currentProperties[index],
                value,
            };
            return newProperties;
        });
    }, []);
    const removeProperty = useCallback((prop) => {
        setProperties(currentProperties => currentProperties.filter(p => p !== prop));
    }, []);

    const update = useCallback(() => {
        updateEnvironmentsVersionMutation({
            variables: {
                application,
                environments: environments.map(e => e.name),
                version: newVersion,
                deploymentStrategy: deploymentStrategy,
                releaseStream: releaseStream,
                properties: properties.map((prop) => ({
                    id: prop.id,
                    value: prop.value,
                })),
            },
        }).then(({ data }) => {
            if (data?.updateEnvironments !== "master") {
                window.open(
                    // @ts-ignore
                    data.updateEnvironmentsVersion,
                    '_blank'
                );
                snackbarService.showSnackbar("A merge request is ready for updating the environments");
            } else {
                snackbarService.showSnackbar("Updates have been queued");
            }

            cancel();
        }).catch((error) => {
            snackbarService.showSnackbar("Something went wrong while updating the environments: " + error.message, 'error');
        });
    }, [ newVersion, environments, releaseStream, properties, deploymentStrategy ]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Card title={`Summary - Update ${environments.length} environment(s)`}>
                    <table className="table">
                        <tr>
                            <th style={{ textAlign: 'left', width: '300px' }}>Applications:</th>
                            <td>{environments.map(e => e.application).filter((v, i, a) => a.indexOf(v) === i).join(' / ')}</td>
                        </tr>
                        <tr>
                            <th style={{ textAlign: 'left' }}>Versions:</th>
                            <td>{environments.map(e => e.applicationVersion).filter((v, i, a) => a.indexOf(v) === i).join(' / ')}</td>
                        </tr>
                        <tr>
                            <th style={{ textAlign: 'left' }}>Release Streams:</th>
                            <td>{environments.map(e => e.releaseStream).filter((v, i, a) => a.indexOf(v) === i).join(' / ')}</td>
                        </tr>
                        <tr>
                            <th style={{ textAlign: 'left' }}>Environments</th>
                            <td>{environments.map(e => e.customerEnvironment).filter((v, i, a) => a.indexOf(v) === i).join(' / ')}</td>
                        </tr>
                        <tr>
                            <th style={{ textAlign: 'left' }}>Customers</th>
                            <td>{environments.map(e => e.customer).filter((v, i, a) => a.indexOf(v) === i).join(' / ')}</td>
                        </tr>
                    </table>
                    {releaseStream == null // New release stream chosen, so that stream should match the new version
                        && environments.map(e => e.releaseStream).filter(stream => !doesVersionMatch(newVersion, stream, data?.releaseStreams || [])).map((rs) => (
                            <Alert key={rs} style={{ marginBottom: '3px' }} severity="warning">The
                                version {newVersion} does not match releasestream {rs}</Alert>
                        ))}
                    {environments.map(e => e.releaseStream).filter((v, i, a) => a.indexOf(v) === i).length > 1 && (
                        <Alert style={{ marginBottom: '3px' }} severity="warning">You have selected multiple release
                            streams</Alert>
                    )}
                    {environments.map(e => e.application).filter((v, i, a) => a.indexOf(v) === i).length > 1 && (
                        <Alert style={{ marginBottom: '3px' }} severity="error">You have selected multiple
                            applications</Alert>
                    )}
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card title="Update">
                    {loading ? (
                        <Alert severity="info">Loading options</Alert>
                    ) : (
                        <React.Fragment>
                            <FormGroup label="New version">
                                {(data?.versions?.length ?? 0) > 0 ? (
                                    <Select
                                        value={newVersion}
                                        clearable={false}
                                        onFetchOptions={data?.versions ?? []}
                                        getOptionLabel={stream => stream}
                                        getOptionValue={stream => stream}
                                        onChange={(option): void => {
                                            setNewVersion(option ?? '')
                                        }}
                                    />
                                ) : (
                                    <Input
                                        value={newVersion ?? ''}
                                        onChange={(event) => setNewVersion(event.target.value)}/>
                                )}
                            </FormGroup>
                            {releaseStreamOptions.length > 0 && (
                                <FormGroup label="Release stream">
                                    <Select
                                        value={releaseStream}
                                        onFetchOptions={releaseStreamOptions}
                                        getOptionLabel={stream => stream}
                                        getOptionValue={stream => stream}
                                        onChange={stream => {
                                            setReleaseStream(stream)
                                        }}
                                    />
                                    {(releaseStream != null && !doesVersionMatch(newVersion, releaseStream, data?.releaseStreams || [])) && (
                                        <Alert style={{ marginBottom: '3px' }} severity="warning">The
                                            version {newVersion} does not match releasestream {releaseStream}</Alert>
                                    )}
                                </FormGroup>
                            )}

                            {(data?.properties.length || 0) > 0 && (
                                <FormGroup label="Properties">
                                    <Grid container spacing={2}>
                                        {properties.map((prop, index) => (
                                            <Grid item xs={12} style={{ display: 'flex' }}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6}>
                                                        <Input disabled value={prop.path}/>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        {prop.type === PropertyType.Boolean ? (
                                                            <Select
                                                                value={prop.value}
                                                                onFetchOptions={['true', 'false']}
                                                                getOptionLabel={o => o}
                                                                getOptionValue={o => o}
                                                                onChange={o => {
                                                                    updateProperty(index, o);
                                                                }}
                                                            />
                                                        ) : prop.type === PropertyType.Enum ? (
                                                            <Select
                                                                value={prop.value}
                                                                onFetchOptions={(prop.allowedValues as string).split(',')}
                                                                getOptionLabel={o => o}
                                                                getOptionValue={o => o}
                                                                onChange={o => {
                                                                    updateProperty(index, o);
                                                                }}
                                                            />
                                                        ) : (prop.type === PropertyType.Integer || prop.type === PropertyType.Double) ? (
                                                            <Input type="number" value={prop.value ?? ''} onChange={event => updateProperty(index, event.target.value)} />
                                                        ) : (
                                                            <Input value={prop.value ?? ''} onChange={event => updateProperty(index, event.target.value)} />
                                                        )}
                                                    </Grid>
                                                </Grid>
                                                <Button textButton icon={<Delete />} onClick={() => removeProperty(prop)}/>
                                            </Grid>
                                        ))}
                                        <Grid item xs={12}>
                                            <Select
                                                key={properties.length}
                                                value={null}
                                                onFetchOptions={data?.properties.filter(o => !properties.some(p => p.path === o.path))}
                                                getOptionLabel={prop => prop.path}
                                                onChange={prop => {
                                                    if (prop != null) {
                                                        setProperties([
                                                            ...properties,
                                                            { ...prop, value: null },
                                                        ]);
                                                    }
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </FormGroup>
                            )}
                        </React.Fragment>
                    )}


                    <FormGroup label="Deployment Strategy">
                        <RadioGroup
                            defaultValue="female"
                            name="deployment-strategy"
                            value={deploymentStrategy}
                            onChange={(event) => setDeploymentStrategy((event.target as HTMLInputElement).value as DeploymentStrategy)}
                        >
                            <FormControlLabel value="Rolling" control={<Radio/>} label="Rolling"/>
                            <FormControlLabel value="Recreate" control={<Radio/>} label="Recreate"/>
                        </RadioGroup>
                    </FormGroup>

                    <div style={{ marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
                        <Button textButton disabled={mutationLoading} onClick={cancel}>
                            Cancel
                        </Button>
                        <Button type="submit" onClick={update}
                                disabled={mutationLoading || !newVersion || environments.map(e => e.application).filter((v, i, a) => a.indexOf(v) === i).length > 1}
                        >
                            {mutationLoading
                                ? <React.Fragment><Loop/> Updating environments</React.Fragment>
                                : <React.Fragment>Update {environments.length} environment(s) now</React.Fragment>
                            }
                        </Button>
                    </div>
                </Card>
            </Grid>
        </Grid>
    );
}
