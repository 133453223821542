import { ConfirmProvider } from 'material-ui-confirm';
import React from 'react';
import  { BrowserRouter as Router } from 'react-router-dom';
import ApplicationRoutes from './ApplicationRoutes';

export default function Application() {
	return (
		<Router>
            <ConfirmProvider>
                <ApplicationRoutes />
            </ConfirmProvider>
		</Router>
	);
}
