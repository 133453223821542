import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import PrivateRoute from '../components/routes/PrivateRoute';
import Dashboard from './pages/dashboard/Dashboard';
import PageLayout from '../components/page/PageLayout';
import EnvironmentRoutes from './pages/environments/EnvironmentRoutes';
import MasterdataRoutes from "./pages/masterdata/MasterdataRoutes";
import UpdateRoutes from "./pages/updates/UpdateRoutes";

const BASE_URL = '/app';

export default function AppRoutes() {
	return <PageLayout>
		<Switch>
			<PrivateRoute path={`${BASE_URL}/dashboard`} component={Dashboard} strict />
			<PrivateRoute path={`${BASE_URL}/environments`} component={EnvironmentRoutes} strict />
			<PrivateRoute path={`${BASE_URL}/masterdata`} component={MasterdataRoutes} strict />
			<PrivateRoute path={`${BASE_URL}/updates`} component={UpdateRoutes} strict />

			<Redirect path="/" to={`${BASE_URL}/dashboard`} />
		</Switch>
	</PageLayout>;
}
