import { UserDetails } from '@cofano/coastguard2-frontend-client';

export enum Permissions {
    ENVIRONMENTS_LIST = 'application/environments/list',
    ENVIRONMENTS_UPDATE = 'application/environments/update',
    RELEASESTREAMS_LIST = 'application/releasestreams/list',
    RELEASESTREAMS_EDIT = 'application/releasestreams/edit',
    PROPERTIES_LIST = 'application/properties/list',
    PROPERTIES_EDIT = 'application/properties/edit',
}

export function hasPermission(userDetails: UserDetails | null, permission: string): boolean {
    return userDetails != null && userDetails.authorities != null && userDetails.authorities.includes(permission);
}

export function hasAnyPermission(userDetails: UserDetails | null, permissions: string[]): boolean {
    return userDetails != null && userDetails.authorities != null && permissions.some(permission => userDetails.authorities.includes(permission));
}

export function isCofanoUser(userDetails: UserDetails | null): boolean {
	return hasPermission(userDetails, 'data/users/isCofanoUser');
}
