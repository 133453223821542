import React, { useMemo } from 'react';

import { Card, CardContent } from '@mui/material';
import Table, { useTable } from '@cofano/cck/Table';
import { ActionProps, ColumnProps } from '@cofano/cck/Table/Table';
import { useForceUpdateMutation, useGetUpdateQueueQuery } from "../../../../__generated__/graphql";
import UpdateStateColumn from "../columns/UpdateStateColumn";
import DateTimeColumn from "../columns/DateTimeColumn";
import { snackbarService } from "@cofano/cck";
import { hasPermission, Permissions } from "../../../../utils/permissions";
import { useUserDetails } from "@cofano/coastguard2-frontend-client";

const columns: Array<ColumnProps> = [
    {
        id: 'state',
        title: 'State',
        render: (_, state) => <UpdateStateColumn state={state} />,
        width: 150,
        disableFlexGrow: true,
    }, {
        id: 'createdOn',
        title: 'Created on',
        render: (_, timestamp) => <DateTimeColumn timestamp={timestamp} />,
    }, {
        id: 'startedOn',
        title: 'Started on',
        render: (_, timestamp) => <DateTimeColumn timestamp={timestamp} />,
    }, {
        id: 'input.application',
        title: 'Application',
    }, {
        id: 'input.environments',
        title: 'Environments',
    }, {
        id: 'input.version',
        title: 'Version',
    }, {
        id: 'input.deploymentStrategy',
        title: 'Deployment strategy',
    }, {
        id: 'input.releaseStream',
        title: 'Release Stream',
    }, {
        id: 'createdBy.displayName',
        title: 'Requested by',
    },
];


export function UpdateQueue() {
    const userDetails = useUserDetails();

    const { data } = useGetUpdateQueueQuery({ pollInterval: 5000 });
    const [ forceUpdate ] = useForceUpdateMutation({ refetchQueries: ['GetUpdateQueue'] });

    const { tableProps: { selectSettings, sortSettings, filterSettings, paginationSettings, content } } = useTable({
        sortSettings: {
            type: 'MULTIPLE',
        },
        selectSettings: {
            selectionEnabled: hasPermission(userDetails, Permissions.ENVIRONMENTS_UPDATE),
        },
        paginationSettings: {
            paginationEnabled: false,
        },
        // @ts-ignore
        content: data?.queue || [],
    });

    const actions: Array<ActionProps> = useMemo(() => {
        if (!hasPermission(userDetails, Permissions.ENVIRONMENTS_UPDATE)) {
            return [];
        }

        return [
            {
                title: 'Force',
                disabled: (selection) => selection.length === 0,
                onClick: (selection) => {
                    forceUpdate({
                        variables: {
                            ids: selection?.map(update => update.id) || [],
                        },
                    }).then(({ data }) => {
                        snackbarService.showSnackbar("Updates have been pushed");
                    }).catch((error) => {
                        snackbarService.showSnackbar("Something went wrong while updating the environments: " + error.message, 'error');
                    });
                },
            },
        ];
    }, []);

    return (
        <Card square variant="outlined">
            <CardContent>
                <Table
                    id={'update_queue'}
                    content={content}
                    columns={columns}
                    actions={actions}
                    paginationSettings={paginationSettings}
                    filterSettings={filterSettings}
                    selectSettings={selectSettings}
                    sortSettings={sortSettings}/>
            </CardContent>
            <CardContent style={{ display: 'flex', justifyContent: 'flex-end' }}>

                <span>Last update performed: <DateTimeColumn timestamp={data?.lastUpdateTimestamp} /></span>
            </CardContent>
        </Card>
    );
}
