import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { EnvironmentList } from './view/EnvironmentList';
import { useParams } from 'react-router';


function EnvironmentListWrapper() {
    const { application } = useParams();
    return <EnvironmentList application={application} key={application} type="applications" title="Applications" />;
}

export default function EnvironmentRoutes() {
    return (
        <Switch>
            <Route path={'/app/environments/:application'} exact>
                <EnvironmentListWrapper />
            </Route>
            <Redirect to="/app/environments" />
        </Switch>
    );
}
