import React from 'react';
import { useCoastguard } from "@cofano/coastguard2-frontend-client";
import { LoadingPage } from "./loading/LoadingPage";

export default function AsyncLoadingComponent({ children }) {
    const { instance: { isInitialized, mainSessionAuthenticationState } } = useCoastguard();

    if (isInitialized && !mainSessionAuthenticationState.loading) {
        return <>{children}</>;
    } else {
        return <LoadingPage />;
    }
}

