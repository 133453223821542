import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Button, FormGroup, Input, Select, snackbarService } from '@cofano/cck';
import {
    PropertyInput,
    PropertyType,
    useCreatePropertyMutation,
    useGetPropertyByIdQuery,
    useUpdatePropertyMutation
} from "../../../../__generated__/graphql";
import CircularProgress from '@mui/material/CircularProgress';
import { Card, CardActions, CardContent } from "@mui/material";

export function PropertyEdit() {
    const { id } = useParams()
    const history = useHistory();

    const [ state, setState ] = useState<PropertyInput>({
        application: '',
        path: '',
        type: PropertyType.String,
        allowedValues: null
    });

    const { loading } = useGetPropertyByIdQuery({
        variables: {
            id: id,
        },
        skip: id == null,
        onCompleted({ property }) {
            setState({
                application: property.application ?? null,
                path: property.path,
                type: property.type,
                allowedValues: property.allowedValues
            });
        }
    });

    const setApplication = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState(currentState => ({ ...currentState, application: event.target.value ?? null }));
    }
    const setPath = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState(currentState => ({ ...currentState, path: event.target.value }));
    }
    const setType = (value: PropertyType) => {
        setState(currentState => ({ ...currentState, type: value }));
    }
    const setAllowedValues = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState(currentState => ({ ...currentState, allowedValues: event.target.value }));
    }

    const [createProperty] = useCreatePropertyMutation();
    const [updateProperty] = useUpdatePropertyMutation();
    const save = () => {
        if (id == null) {
            createProperty({
                variables: {
                    input: state,
                },
            }).then(() => {
                snackbarService.showSnackbar("Property created");
                history.push('/app/masterdata/properties');
            }).catch(error => {
                snackbarService.showSnackbar(error.message, 'error');
            });
        } else {
            updateProperty({
                variables: {
                    id: id,
                    input: state,
                },
            }).then(() => {
                snackbarService.showSnackbar("Property updated");
                history.push('/app/masterdata/properties');
            }).catch(error => {
                snackbarService.showSnackbar(error.message, 'error');
            });
        }
    }

    return (
        <Card square>
            <CardContent>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <>
                        <FormGroup label='Application'>
                            <Input placeholder='Global' value={state.application || ''} onChange={setApplication} />
                        </FormGroup>
                        <FormGroup label='Path' required>
                            <Input value={state.path} onChange={setPath} />
                        </FormGroup>
                        <FormGroup label='Type' required>
                            <Select
                                onFetchOptions={[
                                    PropertyType.String,
                                    PropertyType.Double,
                                    PropertyType.Enum,
                                    PropertyType.Integer,
                                    PropertyType.String
                                ]}
                                value={state.type}
                                onChange={(value) => {
                                    if (value != null) {
                                        setType(value);
                                    }
                                }} />
                        </FormGroup>
                        {state.type === PropertyType.Enum && (
                            <FormGroup label='Allowed values' required>
                                <Input value={state.allowedValues || ''} onChange={setAllowedValues} />
                            </FormGroup>
                        )}
                    </>
                )}
            </CardContent>
            <CardActions sx={{ justifyContent: 'space-between' }}>
                <Button textButton onClick={() =>  history.push('/app/masterdata/properties')}>Cancel</Button>
                <Button onClick={save}>Save</Button>
            </CardActions>
        </Card>
    );
}
