import React from 'react';
import PageHeader from '@cofano/cck/Page';

import Pussy from '../../../assets/pussy.gif';
import { Card } from "@cofano/cck";


const renderPageHeader = () => {
	return <PageHeader>
		<PageHeader.Title>
			Dashboard
		</PageHeader.Title>
	</PageHeader>;
};

export default function Dashboard() {
	return <>
		{renderPageHeader()}

		<Card>
            <img alt="pussy" src={Pussy} style={{ height: '65vh' }} />
		</Card>
	</>;
}
