import React from 'react';

import { Card, CardContent } from '@mui/material';
import Table, { useTable } from '@cofano/cck/Table';
import { ColumnProps } from '@cofano/cck/Table/Table';
import { useGetUpdateHistoryQuery } from "../../../../__generated__/graphql";
import UpdateStateColumn from "../columns/UpdateStateColumn";
import DateTimeColumn from "../columns/DateTimeColumn";

const columns: Array<ColumnProps> = [
    {
        id: 'state',
        title: 'State',
        render: (_, state) => <UpdateStateColumn state={state} />,
        width: 150,
        disableFlexGrow: true,
    }, {
        id: 'finishedOn',
        title: 'Finished',
        render: (_, timestamp) => <DateTimeColumn timestamp={timestamp} />,
    }, {
        id: 'input.application',
        title: 'Application',
    }, {
        id: 'input.environments',
        title: 'Environments',
    }, {
        id: 'input.version',
        title: 'Version',
    }, {
        id: 'input.deploymentStrategy',
        title: 'Deployment strategy',
    }, {
        id: 'input.releaseStream',
        title: 'Release Stream',
    }, {
        id: 'createdBy.displayName',
        title: 'Requested by',
    },
];


export function UpdateHistory() {
    const { data } = useGetUpdateHistoryQuery();

    const { tableProps: { selectSettings, sortSettings, filterSettings, paginationSettings, content } } = useTable({
        sortSettings: {
            type: 'MULTIPLE',
        },
        selectSettings: {
            selectionEnabled: false,
        },
        paginationSettings: {
            paginationEnabled: false,
        },
        // @ts-ignore
        content: data?.history || [],
    });

    return (
        <Card square variant="outlined">
            <CardContent>
                <Table
                    id={'update_queue'}
                    content={content}
                    columns={columns}
                    paginationSettings={paginationSettings}
                    filterSettings={filterSettings}
                    selectSettings={selectSettings}
                    sortSettings={sortSettings}/>
            </CardContent>
        </Card>
    );
}
