import React from 'react';
import { useCoastguard, UserDetails } from '@cofano/coastguard2-frontend-client';
import { Redirect, Route } from 'react-router-dom';

interface PrivateRouteProps {
    authorization?: (userDetails: UserDetails) => boolean;
    component?: unknown;
    componentProps?: unknown;
    render?: (any) => JSX.Element | null;
    location?: {
        pathname?: string;
    };
    [x: string]: unknown;
}

export default function PrivateRoute({ authorization, location: routeLocation, component: Component, render, componentProps, ...rest }: PrivateRouteProps) {
    const { instance: { isAuthenticated, getUserDetails: userDetails } } = useCoastguard();

    return (
        <Route
            {...rest}
            render={(props) => {
                if (isAuthenticated) {
                    if (authorization == null || authorization(userDetails)) {
                        if (Component) {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            return <Component {...props} {...componentProps} />;
                        } else if (render) {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            return render({ ...props, ...componentProps });
                        } else {
                            return null;
                        }
                    } else {
                        return <Redirect to='/' />;
                    }
                } else {
                    return (
                        <Redirect
                            to={{
                                pathname: '/auth/login',
                                state: { redirectLocation: routeLocation ? routeLocation.pathname : null },
                            }} />
                    );
                }
            }
            } />);
}

