import React from 'react';
import { CircularProgress } from '@mui/material';

export const LoadingPage = () => {
	const loadingPage = {
		root: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			height: '100vh',
			backgroundColor: '#FFFFFF',
		},
	};

	return <div style={loadingPage.root}>
		<CircularProgress thickness={2} size={100} disableShrink /></div>;
};
