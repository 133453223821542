import React from 'react';
import AsyncLoadingComponent from '../components/AsyncLoadingComponent';
import { Redirect, Route, Switch } from 'react-router-dom';
import AuthRoutes from './AuthRoutes';
import AppRoutes from './AppRoutes';
import PrivateRoute from "../components/routes/PrivateRoute";


export default function ApplicationRoutes() {
	return (
		<AsyncLoadingComponent>
			<Switch>
				<Route path="/auth" component={AuthRoutes} strict />
				<PrivateRoute path="/app" component={AppRoutes} strict />

				<Redirect path="/" to="/app" />
			</Switch>
		</AsyncLoadingComponent>
	);
}
