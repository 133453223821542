import React, { useEffect, useMemo, useState } from 'react';

import { useGetEnvironmentsQuery } from '../../../../__generated__/graphql';
import { Card, CardContent } from '@mui/material';
import Table, { useTable } from '@cofano/cck/Table';
import { ActionProps, ColumnProps } from '@cofano/cck/Table/Table';
import { EnvironmentsUpdate } from '../EnvironmentsUpdate';
import { useUserDetails } from "@cofano/coastguard2-frontend-client";
import { hasPermission, Permissions } from "../../../../utils/permissions";

const columns: Array<ColumnProps> = [
    {
        id: 'application',
        title: 'Application',
        sortable: true,
    }, {
        id: 'namespace',
        title: 'Namespace',
        sortable: true,
    }, {
        id: 'customer',
        title: 'Customer',
        sortable: true,
        filterComponent: 'TEXT',
    }, {
        id: 'customerEnvironment',
        title: 'Customer Environment',
        sortable: true,
        filterComponent: 'TEXT',
    }, {
        id: 'applicationVersion',
        title: 'Version',
        sortable: true,
        filterComponent: 'TEXT',
    }, {
        id: 'releaseStream',
        title: 'Release Stream',
        sortable: true,
        filterComponent: 'TEXT',
    },
];


export function EnvironmentList({ type, title, application }) {
    const userDetails = useUserDetails();

    const { data } = useGetEnvironmentsQuery({
        variables: {
            application,
        },
    });

    const [ updateList, setUpdateList ] = useState<null | any[]>(null);
    useEffect(() => {
        setUpdateList(null);
    }, [ application ]);

    const { tableProps: { selectSettings, sortSettings, filterSettings, paginationSettings, content } } = useTable({
        sortSettings: {
            type: 'MULTIPLE',
        },
        selectSettings: {
            selectionEnabled: hasPermission(userDetails, Permissions.ENVIRONMENTS_UPDATE),
        },
        paginationSettings: {
            paginationEnabled: false,
        },
        // @ts-ignore
        content: data?.environments.map(app => ({ ...app, id: app.name })) || [],
    });

    const actions: Array<ActionProps> = useMemo(() => {
        if (!hasPermission(userDetails, Permissions.ENVIRONMENTS_UPDATE)) {
            return [];
        }
        return [
            {
                title: 'Update',
                disabled: (selection) => selection.length === 0,
                onClick: (selection) => setUpdateList(selection),
            },
        ];
    }, [ setUpdateList, data?.environments ]);

    const onRowDoubleClick = useMemo(() => {
        if (!hasPermission(userDetails, Permissions.ENVIRONMENTS_UPDATE)) {
            return undefined;
        }

        return (row: { id?: number | undefined; }) => {
            setUpdateList([ row ]);
        };
    }, []);

    return (
        updateList
            ?
            <EnvironmentsUpdate application={application} environments={updateList} cancel={() => setUpdateList(null)}/>
            : <Card square variant="outlined">
                <CardContent>
                    <Table
                        id={'environment_list_' + type}
                        content={content}
                        columns={columns}
                        actions={actions}
                        onRowDoubleClick={onRowDoubleClick}
                        paginationSettings={paginationSettings}
                        filterSettings={filterSettings}
                        selectSettings={selectSettings}
                        sortSettings={sortSettings}/>
                </CardContent>
            </Card>

    );
}
