import React, { useEffect } from 'react';
import { useCoastguard } from '@cofano/coastguard2-frontend-client';
import { LoadingPage } from "../../../components/loading/LoadingPage";

export default function Login() {
    const { instance: { isAuthenticated, isManuallyLoggedOut, authorizeApplication } } = useCoastguard();

    const login = () => {
        authorizeApplication().then((response) => {
            window.location.href = response;
        });
    };

    useEffect(() => {
        if (!isManuallyLoggedOut && !isAuthenticated) {
            login();
        }
    }, [ isAuthenticated, isManuallyLoggedOut, login ]);

    if (isManuallyLoggedOut) {
        return (<div>
            <div className="actionButtonWrapper">
                <button
                    onClick={login}
                    className={'btn btn-stack'}>
                    Log In
                </button>
            </div>
        </div>);
    } else {
        return <LoadingPage />;
    }
}
