import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useConfirm } from "material-ui-confirm";

import {
    ReleaseStream,
    useDeleteReleaseStreamMutation,
    useGetReleaseStreamsQuery
} from '../../../../__generated__/graphql';
import Table, { useTable } from '@cofano/cck/Table';
import { ActionProps, ColumnProps, RowActionProps } from '@cofano/cck/Table/Table';
import { Card, snackbarService } from '@cofano/cck';
import { hasPermission, Permissions } from "../../../../utils/permissions";
import { useUserDetails } from "@cofano/coastguard2-frontend-client";
import { Delete } from "@mui/icons-material";

const columns: Array<ColumnProps> = [
    {
        id: 'application',
        title: 'Application',
        sortable: true,
        filterComponent: 'TEXT',
    }, {
        id: 'stream',
        title: 'Release stream',
        sortable: true,
        filterComponent: 'TEXT',
    }, {
        id: 'regex',
        title: 'Regex',
        sortable: true,
        filterComponent: 'TEXT',
    },
];


export function ReleaseStreamList() {
    const history = useHistory();
    const userDetails = useUserDetails();
    const confirm = useConfirm();

    const { data, loading } = useGetReleaseStreamsQuery();
    const [ deleteReleaseStream ] = useDeleteReleaseStreamMutation({
        refetchQueries: ['GetReleaseStreams'],
        onCompleted() {
            snackbarService.showSnackbar("Release stream deleted");
        },
        onError(error) {
            snackbarService.showSnackbar("Something went wrong while deleting the release stream: " + error.message, 'error');
        }
    });

    const { tableProps: { selectSettings, sortSettings, filterSettings, paginationSettings, content } } = useTable({
        sortSettings: {
            type: 'MULTIPLE',
        },
        selectSettings: {
            selectionEnabled: false,
        },
        paginationSettings: {
            paginationEnabled: false,
        },
        content: data?.releaseStreams || [],
    });

    const actions: Array<ActionProps> = useMemo(() => {
        if (!hasPermission(userDetails, Permissions.RELEASESTREAMS_EDIT)) {
            return [];
        }
        return [
            {
                title: 'Add',
                onClick: () => history.push('/app/masterdata/releasestreams/new'),
                pinned: true,
            },
        ];
    }, []);
    const rowActions: Array<RowActionProps> = useMemo(() => ([
        {
            icon: <Delete />,
            hidden: !hasPermission(userDetails, Permissions.RELEASESTREAMS_EDIT),
            onClick: (row: ReleaseStream) => {
                confirm().then(() => {
                    deleteReleaseStream({
                        variables: {
                            id: row.id,
                        },
                    })
                });
            },
        }
    ]), []);

    const onRowDoubleClick = useMemo(() => {
        if (!hasPermission(userDetails, Permissions.RELEASESTREAMS_EDIT)) {
            return undefined;
        }

        return (row: { id?: number | undefined; }) => {
            history.push('/app/masterdata/releasestreams/edit/' + row.id);
        };
    }, []);

    return (
        <Card>
            <Table
                id={'masterdata_release_streams'}
                title='Release streams'
                content={content}
                contentLoading={loading}
                columns={columns}
                actions={actions}
                rowActions={rowActions}
                onRowDoubleClick={onRowDoubleClick}
                paginationSettings={paginationSettings}
                filterSettings={filterSettings}
                selectSettings={selectSettings}
                sortSettings={sortSettings}/>
        </Card>
    );
}
