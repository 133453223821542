import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { FormGroup, Input, Button, snackbarService } from '@cofano/cck';
import {
    useCreateReleaseStreamMutation,
    useGetReleaseStreamByIdQuery,
    useUpdateReleaseStreamMutation
} from "../../../../__generated__/graphql";
import CircularProgress from '@mui/material/CircularProgress';
import { Card, CardActions, CardContent } from "@mui/material";

type ReleaseStreamInput = {
    application: string;
    stream: string;
    regex: string | null | undefined;
}

export function ReleaseStreamEdit() {
    const { id } = useParams()
    const history = useHistory();

    const [ state, setState ] = useState<ReleaseStreamInput>({
        application: '',
        stream: '',
        regex: '',
    });

    const { loading } = useGetReleaseStreamByIdQuery({
        variables: {
            id: id,
        },
        skip: id == null,
        onCompleted({ stream }) {
            setState({
                application: stream.application,
                stream: stream.stream,
                regex: stream.regex,
            });
        }
    });

    const setApplication = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState(currentState => ({ ...currentState, application: event.target.value }));
    }
    const setStream = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState(currentState => ({ ...currentState, stream: event.target.value }));
    }
    const setRegex = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState(currentState => ({ ...currentState, regex: event.target.value }));
    }

    const [createReleaseStream] = useCreateReleaseStreamMutation();
    const [updateReleaseStream] = useUpdateReleaseStreamMutation();
    const save = () => {
        if (id == null) {
            createReleaseStream({
                variables: {
                    input: state,
                },
            }).then(() => {
                snackbarService.showSnackbar("Release stream created");
                history.push('/app/masterdata/releasestreams');
            }).catch(error => {
                snackbarService.showSnackbar(error.message, 'error');
            });
        } else {
            updateReleaseStream({
                variables: {
                    id: id,
                    input: state,
                },
            }).then(() => {
                snackbarService.showSnackbar("Release stream updated");
                history.push('/app/masterdata/releasestreams');
            }).catch(error => {
                snackbarService.showSnackbar(error.message, 'error');
            });
        }
    }

    return (
        <Card square>
            <CardContent>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <>
                        <FormGroup label='Application' required>
                            <Input value={state.application} onChange={setApplication} />
                        </FormGroup>
                        <FormGroup label='Stream' required>
                            <Input value={state.stream} onChange={setStream} />
                        </FormGroup>
                        <FormGroup label='Regex'>
                            <Input value={state.regex || ''} onChange={setRegex} />
                        </FormGroup>
                    </>
                )}
            </CardContent>
            <CardActions sx={{ justifyContent: 'space-between' }}>
                <Button textButton onClick={() =>  history.push('/app/masterdata/releasestreams')}>Cancel</Button>
                <Button onClick={save}>Save</Button>
            </CardActions>
        </Card>
    );
}
