import React from 'react';
import ReactDOM from 'react-dom/client';

import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import generatedIntrospection from './__generated__/possible-types';

import { ThemeProvider, DefaultTheme } from '@cofano/cck';
import Application from "./containers/Application";
import {ToastProvider} from "react-toast-notifications";

import { CoastguardProvider } from '@cofano/coastguard2-frontend-client';
import { coastguardInstance } from './security';

import './app.scss';

// GraphQL Apollo config
const cache = new InMemoryCache({
    possibleTypes: generatedIntrospection.possibleTypes,
    typePolicies: {
        Query: {
            fields: {
                alerts: {
                    merge(existing, incoming) {
                        return incoming;
                    },
                },
            },
        },
    },
});
const link = createHttpLink({
    uri: '/api/graphql',
    fetch: coastguardInstance.fetch,
});
const client = new ApolloClient({
    cache,
    link,
    connectToDevTools: true,
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'cache-and-network',
        },
    },
});

// Render actual application
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <CoastguardProvider instance={coastguardInstance}>
          <ThemeProvider theme={DefaultTheme}>
              <ToastProvider autoDismiss={true}>
                  <ApolloProvider client={client}>
                      <Application />
                  </ApolloProvider>
              </ToastProvider>
          </ThemeProvider>
      </CoastguardProvider>
  </React.StrictMode>
);
