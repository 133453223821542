import React, { useMemo, useState } from 'react';
import Layout, { ToolbarItem } from '@cofano/cck/Layout';
import {
	Apps,
	Dashboard,
    Storage,
    AccountCircle,
    DynamicFeed,
} from '@mui/icons-material';
import { Nav } from '@cofano/cck';

import { useGetApplicationsQuery, useGetSettingsQuery } from '../../__generated__/graphql';
import { useCoastguard, useUserDetails } from "@cofano/coastguard2-frontend-client";
import { useLocation, useHistory } from 'react-router-dom';
import { hasAnyPermission, hasPermission, Permissions } from "../../utils/permissions";


interface PageLayoutProps {
	children?: React.ReactNode;
}

const Navigation = () => {
    const history = useHistory();
    const { pathname } = useLocation();
    const userDetails = useUserDetails();

    const [ openItems, setOpenItems ] = useState<string[]>(['applications']);
    const isOpen = (id: string) => openItems.includes(id);
    const toggle = (id: string) => setOpenItems(currItems => isOpen(id) ? currItems.filter(i => i !== id) : [ ...currItems, id ]);

	const { data } = useGetApplicationsQuery();
	const applications = useMemo(() => {
		const newApplications = data?.applications || [];
		return [ ...newApplications ].sort();
	}, [ data ]);

	return (
		<Nav
            navigate={url => history.push(url)}
			items={[ {
				label: 'Dashboard',
				icon: <Dashboard />,
				url: '/app/dashboard',
                active: pathname.startsWith('/app/dashboard'),
			}, {
				label: 'Applications',
				icon: <Apps />,
				onClick: () => toggle('applications'),
				expanded: pathname.startsWith('/app/environments/') || isOpen('applications'),
                active: pathname.startsWith('/app/environments/'),
                disabled: !hasPermission(userDetails, Permissions.ENVIRONMENTS_LIST),

				items: applications.map(application => ({
					label: application,
					url: '/app/environments/' + application,
                    active: pathname.startsWith('/app/environments/' + application),
				})),
			}, {
                label: 'Updates',
                icon: <DynamicFeed />,
                onClick: () => toggle('updates'),
                expanded: pathname.startsWith('/app/updates/') || isOpen('updates'),
                active: pathname.startsWith('/app/updates/'),
                disabled: !hasPermission(userDetails, Permissions.ENVIRONMENTS_LIST),

                items: [{
                    label: 'Queue',
                    url: '/app/updates/queue',
                    active: pathname.startsWith('/app/updates/queue'),
                }, {
                    label: 'History',
                    url: '/app/updates/history',
                    active: pathname.startsWith('/app/updates/history'),
                }]
            }, {
                label: 'Masterdata',
                icon: <Storage />,
                onClick: () => toggle('masterdata'),
                expanded: pathname.startsWith('/app/masterdata/') || isOpen('masterdata'),
                active: pathname.startsWith('/app/masterdata/'),
                disabled: !hasAnyPermission(userDetails, [ Permissions.RELEASESTREAMS_LIST, Permissions.PROPERTIES_LIST ]),

                items: [{
                    label: 'Properties',
                    url: '/app/masterdata/properties',
                    active: pathname.startsWith('/app/masterdata/properties'),
                    disabled: !hasPermission(userDetails, Permissions.PROPERTIES_LIST),
                }, {
                    label: 'Release streams',
                    url: '/app/masterdata/releasestreams',
                    active: pathname.startsWith('/app/masterdata/releasestreams'),
                    disabled: !hasPermission(userDetails, Permissions.RELEASESTREAMS_LIST),
                }]
            } ]} />
	);
};

export default function PageLayout({ children }: PageLayoutProps) {
	const { instance: { getUserDetails: userDetails, logout } } = useCoastguard();
    const { data } = useGetSettingsQuery();
	return (
        <React.Fragment>
            <Layout>
                <Layout.Top title="Project Octopus" customer="Cofano">
                    <Layout.Toolbar>
                        <ToolbarItem
                            border
                            icon={<AccountCircle />}
                            onClick={logout}
                            label={userDetails?.displayName} />
                    </Layout.Toolbar>
                </Layout.Top>

                <Layout.Wrapper>
                    <Layout.Sidebar version={data?.version || 'unknown'}>
                        <Navigation />
                    </Layout.Sidebar>

                    <Layout.Main full={false}>
                        {children}
                    </Layout.Main>
                </Layout.Wrapper>
            </Layout>
            {data?.css ? <style>{data?.css}</style> : null}
        </React.Fragment>
	);
}
