import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PropertyList } from "./properties/PropertyList";
import { ReleaseStreamList } from "./releasestreams/ReleaseStreamList";
import { ReleaseStreamEdit } from "./releasestreams/ReleaseStreamEdit";
import { PropertyEdit } from "./properties/PropertyEdit";


export default function MasterdataRoutes() {
    return (
        <Switch>
            <Route path={'/app/masterdata/properties'} exact>
                <PropertyList />
            </Route>
            <Route path={'/app/masterdata/properties/new'} exact>
                <PropertyEdit />
            </Route>
            <Route path={'/app/masterdata/properties/edit/:id'} exact>
                <PropertyEdit />
            </Route>
            <Route path={'/app/masterdata/releasestreams'} exact>
                <ReleaseStreamList />
            </Route>
            <Route path={'/app/masterdata/releasestreams/new'} exact>
                <ReleaseStreamEdit />
            </Route>
            <Route path={'/app/masterdata/releasestreams/edit/:id'} exact>
                <ReleaseStreamEdit />
            </Route>
        </Switch>
    );
}
