import React from 'react';

const StateColors = {
    QUEUED: '#03a9f4',
    IN_PROGRESS: '#7d17d9',
    FINISHED: '#4caf50',
    ERROR: '#ef5350',
}
const StateNames = {
    QUEUED: 'Queued',
    IN_PROGRESS: 'In progress',
    FINISHED: 'Finished',
    ERROR: 'Error',
}

export default function UpdateStateColumn({ state }) {
    return <div style={{
        display: 'inline-block',
        padding: '0 8px',
        backgroundColor: StateColors[state] + '88',
        border: '2px solid ' + StateColors[state],
        borderRadius: 4,
    }}>{StateNames[state]}</div>;
}
