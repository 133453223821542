import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useConfirm } from "material-ui-confirm";

import {
    Property,
    useDeletePropertyMutation,
    useGetPropertiesQuery
} from '../../../../__generated__/graphql';
import Table, { useTable } from '@cofano/cck/Table';
import { ActionProps, ColumnProps, RowActionProps } from '@cofano/cck/Table/Table';
import { Card, snackbarService } from '@cofano/cck';
import { useUserDetails } from "@cofano/coastguard2-frontend-client";
import { hasPermission, Permissions } from "../../../../utils/permissions";
import { Delete } from "@mui/icons-material";

const columns: Array<ColumnProps> = [
	{
		id: 'application',
		title: 'Application',
		sortable: true,
        filterComponent: 'TEXT',
	}, {
        id: 'path',
        title: 'Property',
        sortable: true,
        filterComponent: 'TEXT',
    }, {
        id: 'type',
        title: 'Type',
        sortable: true,
        filterComponent: 'TEXT',
    }, {
		id: 'allowedValues',
		title: 'Allowed Values',
		sortable: false,
	},
];



export function PropertyList() {
    const history = useHistory();
    const userDetails = useUserDetails();
    const confirm = useConfirm();

	const { data, loading } = useGetPropertiesQuery();
    const [ deleteProperty ] = useDeletePropertyMutation({
        refetchQueries: ['GetProperties'],
        onCompleted() {
            snackbarService.showSnackbar("Property deleted");
        },
        onError(error) {
            snackbarService.showSnackbar("Something went wrong while deleting the property: " + error.message, 'error');
        }
    });

	const { tableProps: { selectSettings, sortSettings, filterSettings, paginationSettings, content } } = useTable({
		sortSettings: {
			type: 'MULTIPLE',
		},
		selectSettings: {
			selectionEnabled: false,
		},
		paginationSettings: {
			paginationEnabled: false,
		},
        content: data?.properties || [],
	});

    const actions: Array<ActionProps> = useMemo(() => {
        if (!hasPermission(userDetails, Permissions.PROPERTIES_EDIT)) {
            return [];
        }
        return [
            {
                title: 'Add',
                onClick: () => history.push('/app/masterdata/properties/new'),
                pinned: true,
            },
        ];
    }, []);

    const rowActions: Array<RowActionProps> = useMemo(() => ([
        {
            icon: <Delete />,
            hidden: !hasPermission(userDetails, Permissions.PROPERTIES_EDIT),
            onClick: (row: Property) => {
                confirm().then(() => {
                    deleteProperty({
                        variables: {
                            id: row.id,
                        },
                    })
                });
            },
        }
    ]), []);


    const onRowDoubleClick = useMemo(() => {
        if (!hasPermission(userDetails, Permissions.PROPERTIES_EDIT)) {
            return undefined;
        }

        return (row: { id?: number | undefined; }) => {
            history.push('/app/masterdata/properties/edit/' + row.id);
        };
    }, []);

	return (
        <Card>
            <Table
                id={'masterdata_properties'}
                content={content}
                contentLoading={loading}
                columns={columns}
                actions={actions}
                rowActions={rowActions}
                onRowDoubleClick={onRowDoubleClick}
                paginationSettings={paginationSettings}
                filterSettings={filterSettings}
                selectSettings={selectSettings}
                sortSettings={sortSettings} />
        </Card>
	);
}
