import React from 'react';
import { useCoastguard } from '@cofano/coastguard2-frontend-client';
import { Redirect, Route } from 'react-router-dom';

interface LoginRouteProps {
    component?: unknown;
    componentProps?: unknown;
    render?: (any) => JSX.Element | null;
    location?: {
        state?: {
            redirectLocation?: string;
        };
    };
    [x: string]: unknown;
}

export default function LoginRoute({ location: routeLocation, component: Component, componentProps = {}, render, ...rest }: LoginRouteProps) {
    const { instance: { isAuthenticated } } = useCoastguard();

    return (
        <Route
            {...rest} render={(props) => {
            if (!isAuthenticated) {
                if (Component) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    return <Component {...props} {...componentProps} />;
                } else if (render) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    return render({ ...props, ...componentProps });
                } else {
                    return null;
                }
            } else if (routeLocation != null && routeLocation.state != null && routeLocation.state.redirectLocation != null) {
                return <Redirect
                    to={{
                        pathname: routeLocation.state.redirectLocation }} />;
            }
        }} />
    );

}
