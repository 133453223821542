import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useCoastguard } from '@cofano/coastguard2-frontend-client';

interface AuthorizeRouteProps {
    component?: unknown;
    componentProps?: unknown;
    render?: (any) => JSX.Element | null;
    location?: {
        hash?: string;
        state?: {
            redirectLocation?: string;
        };
    };
    [x: string]: unknown;
}

export default function AuthorizeRoute({ location: routeLocation, component: Component, componentProps = {}, render, ...rest }: AuthorizeRouteProps) {
    const { instance: { isAuthenticating, isAuthenticated } } = useCoastguard();

    return (
        <Route
            {...rest}
            render={(props) => {
                if (!isAuthenticating && !isAuthenticated) {
                    const authorizationCode = routeLocation?.hash?.substring('#code='.length) || null;

                    if (Component) {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        return <Component {...props} {...componentProps} authorizationCode={authorizationCode}/>;
                    } else if (render) {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        return render({ ...props, ...componentProps, authorizationCode });
                    } else {
                        return null;
                    }
                } else if (routeLocation != null && routeLocation.state != null && routeLocation.state.redirectLocation != null) {
                    return (<Redirect
                        to={{
                            pathname: routeLocation.state.redirectLocation,
                        }}/>);
                } else {
                    return <Redirect to='/'/>;
                }
            }}/>
    );

}
