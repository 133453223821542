import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { UpdateQueue } from "./queue/UpdateQueue";
import { UpdateHistory } from "./history/UpdateHistory";

export default function UpdateRoutes() {
    return (
        <Switch>
            <Route path={'/app/updates/queue'} exact>
                <UpdateQueue />
            </Route>
            <Route path={'/app/updates/history'} exact>
                <UpdateHistory />
            </Route>
            <Redirect to="/app/updates/queue" />
        </Switch>
    );
}
