import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import LoginRoute from '../components/routes/LoginRoute';
import AuthorizeApplication from "./pages/login/AuthorizeApplication";
import AuthorizeRoute from "../components/routes/AuthorizeRoute";
import Login from "./pages/login/Login";

const BASE_URL = '/auth';

export default function AuthRoutes({ ...rest }) {
	return (
		<Switch>
			<LoginRoute path={`${BASE_URL}/login`} exact component={Login} />
            <AuthorizeRoute path={`${BASE_URL}/authorize`} component={AuthorizeApplication} exact />
			<Redirect path={`${BASE_URL}`} to={`${BASE_URL}/login`} />
		</Switch>
	);
}
