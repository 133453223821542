import { useEffect } from "react";
import { useCoastguard } from "@cofano/coastguard2-frontend-client";

export default function AuthorizeApplication({ authorizationCode }) {
    const { instance: { isAuthenticating, isAuthenticated, completeApplicationAuthorization } } = useCoastguard();

    const authorizeApplication = () => {
        if (authorizationCode == null || authorizationCode.trim().length === 0) {
            console.error('Invalid authorization code');
        }

        completeApplicationAuthorization(authorizationCode);
    };

    useEffect(() => {
        if (!isAuthenticating && !isAuthenticated) {
            authorizeApplication();
        }
    }, [ isAuthenticating, isAuthenticated, authorizeApplication ]);

    return "Loading";
}
